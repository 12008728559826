import { ofetch } from 'ofetch'
export default () => {
  const config = useRuntimeConfig()
  const controller = new AbortController()
  const signal = controller.signal
  const timeout = setTimeout(() => controller.abort(), config.public.API_TIMEOUT)

  return ofetch.create({
    baseURL: config.public.SEARCHAPI_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
    async onRequestError({ error }) {
      clearTimeout(timeout)
      throw error
    },
    async onResponse() {
      clearTimeout(timeout)
    },
    async onResponseError({ request, response }) {
      clearTimeout(timeout)
      console.error('[fetch response error]', request, response.status, response.body)
    },
  })
}
